import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DebugClockService {
  private readonly ELEMENT_ID = 'tc__debug-clock';

  public show() {
    let e = document.getElementById(this.ELEMENT_ID);

    if (!e) {
      e = document.createElement('div');
      e.id = this.ELEMENT_ID;
      e.style.position = 'fixed';
      e.style.top = '90px';
      e.style.right = '40px';
      e.style.pointerEvents = 'none';
      e.style.fontFamily = 'monospace';
      e.style.fontSize = '16px';
      e.style.zIndex = '999999999999999999';
      document.body.appendChild(e);
    }

    setInterval(() => {
      const date = new Date();
      e.innerHTML =
        date.getHours().toString().padStart(2, '0') +
        ':' +
        date.getMinutes().toString().padStart(2, '0') +
        ':' +
        date.getSeconds().toString().padStart(2, '0') +
        '.' +
        Math.floor(date.getMilliseconds() / 100).toString();
    }, 100);
  }

  public hide() {
    let e = document.getElementById(this.ELEMENT_ID);
    if (e) {
      document.body.removeChild(e);
    }
  }
}
