import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('XSRF-TOKEN');
    if (token) {
      const req = request.clone({
        headers: request.headers.set('X-XSRF-TOKEN', token),
      });
      return next.handle(req);
    }
    return next.handle(request);
  }
}
