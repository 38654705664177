export * from './adminActuator.service';
import {AdminActuatorService} from './adminActuator.service';
import {AdminRoomService} from './adminRoom.service';
import {AdminRoomGroupService} from './adminRoomGroup.service';
import {AdminSensorService} from './adminSensor.service';
import {AdminSettingsService} from './adminSettings.service';
import {AdminUserService} from './adminUser.service';
import {AdminUserGroupService} from './adminUserGroup.service';
import {ConfigService} from './config.service';
import {PasswordResetService} from './passwordReset.service';
import {RoomService} from './room.service';
import {RootService} from './root.service';
import {SessionService} from './session.service';
import {TestDataService} from './testData.service';

export * from './adminRoom.service';
export * from './adminRoomGroup.service';
export * from './adminSensor.service';
export * from './adminSettings.service';
export * from './adminUser.service';
export * from './adminUserGroup.service';
export * from './config.service';
export * from './passwordReset.service';
export * from './room.service';
export * from './root.service';
export * from './session.service';
export * from './testData.service';
export const APIS = [AdminActuatorService, AdminRoomService, AdminRoomGroupService, AdminSensorService, AdminSettingsService, AdminUserService, AdminUserGroupService, ConfigService, PasswordResetService, RoomService, RootService, SessionService, TestDataService];
