import { Injectable } from '@angular/core';
import { SecurityRole, UserDTO } from 'src/app/api/client';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor() {
    const user = this.getUserFromSession();
    if (user) {
      Sentry.setUser({id: user.id, username: user.username});
    }
  }

  /**
   * Check whether a user is logged in.
   * @returns true, if a user is signed in.
   */
  public async isAuthenticated(): Promise<boolean> {
    return !!this.getJwtToken();
  }

  public hasRole(role: SecurityRole): boolean {
    const user = this.getUserFromSession();
    return (
      user.roles.includes(role) ||
      user.roles.includes(SecurityRole.Administrator)
    );
  }

  public invalidateSession() {
    localStorage.removeItem('session.user');
    localStorage.removeItem('session.token');
    localStorage.removeItem('session.refresh-token');
    Sentry.setUser(null);
  }

  public writeSessionData(user: UserDTO, token: string, refreshToken: string) {
    localStorage.setItem('session.user', JSON.stringify(user));
    localStorage.setItem('session.token', token);
    localStorage.setItem('session.refresh-token', refreshToken);
    Sentry.setUser({id: user.id, username: user.username});
  }

  public getUserFromSession(): UserDTO {
    const userString = localStorage.getItem('session.user');
    try {
      return JSON.parse(userString);
    } catch (error) {
      return null;
    }
  }

  public getJwtToken(): string {
    return localStorage.getItem('session.token');
  }

  public getRefreshToken(): string {
    return localStorage.getItem('session.refresh-token');
  }
}
