import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DebugClockService } from './services/util/debug-clock.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(route: ActivatedRoute, debugClock: DebugClockService) {
    route.queryParamMap.subscribe((params) => {
      if (params.get('clock')) {
        debugClock.show();
      }
      // Do not hide clock again in order to keep it visible after navigation to another page
    });
  }
}
