/**
 * Templado API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * DO NOT RENAME THESE ENUM MEMBERS!  They are stored in the database as strings.
 */
export type SecurityRole = 'Invalid' | 'Administrator' | 'ManageRooms' | 'ManageRoomGroups' | 'ManageUsers' | 'ManageUserGroups' | 'ManageActuators' | 'ManageSensors' | 'ManageSystemSettings';

export const SecurityRole = {
    Invalid: 'Invalid' as SecurityRole,
    Administrator: 'Administrator' as SecurityRole,
    ManageRooms: 'ManageRooms' as SecurityRole,
    ManageRoomGroups: 'ManageRoomGroups' as SecurityRole,
    ManageUsers: 'ManageUsers' as SecurityRole,
    ManageUserGroups: 'ManageUserGroups' as SecurityRole,
    ManageActuators: 'ManageActuators' as SecurityRole,
    ManageSensors: 'ManageSensors' as SecurityRole,
    ManageSystemSettings: 'ManageSystemSettings' as SecurityRole
};

