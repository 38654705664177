/**
 * Templado API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdminSettingControlTypeDTO = 'TextBox' | 'PasswordField' | 'CheckBox' | 'TimePicker';

export const AdminSettingControlTypeDTO = {
    TextBox: 'TextBox' as AdminSettingControlTypeDTO,
    PasswordField: 'PasswordField' as AdminSettingControlTypeDTO,
    CheckBox: 'CheckBox' as AdminSettingControlTypeDTO,
    TimePicker: 'TimePicker' as AdminSettingControlTypeDTO
};

