import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://c567f583a558045c6a6b3cd38c164c0a@o4507624405336064.ingest.de.sentry.io/4507624410316880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "127.0.0.1", /^https:\/\/(staging\.)?heizung\.hilaren\.de/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: window.location.hostname.startsWith('staging.') ? 'staging' :
    window.location.hostname == 'localhost' ? 'development' :
      window.location.hostname == '127.0.0.1' ? 'development' :
        'production',
});

globalThis.appVersion = '2.0.2';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
